<div class="user-tracking-form">
  <div>
    <div *ngIf="scenario.settings.tracking.instructions" class="mb-2">
      {{ scenario.settings.tracking.instructions }}
    </div>
    <div
      *ngIf="
        !scenario.settings.tracking.fields ||
        (scenario.settings.tracking.fields &&
          scenario.settings.tracking.fields.length == 0)
      "
    >
      No fields set - please go to settings and set your tracking fields.
    </div>
    <form
      (ngSubmit)="onSubmit()"
      #userTrackingForm="ngForm"
      *ngIf="scenario.settings.tracking.fields"
    >
      <div *ngIf="scenario.settings.tracking.fields.length > 0">
        <div class="form-container">
          <div
            class="form-group mb-1"
            *ngFor="let field of scenario.settings.tracking.fields"
            [ngClass]="{ 'form-switch': field.type == 'checkbox' }"
          >
            <label for="{{ field.id }}" class="form-label mb-2">{{
              field.name
            }}</label>
            <ng-container [ngSwitch]="field.type">
              <div *ngSwitchCase="'text'" class="has-validation">
                <input
                  type="text"
                  name="{{ field.id }}"
                  id="{{ field.id }}"
                  [(ngModel)]="formData[field.id]"
                  [required]="field.required"
                  class="form-control"
                  #fieldText="ngModel"
                  [ngClass]="{ 'is-invalid': !fieldText.valid && submitted }"
                  [attr.placeholder]="
                  field.placeholder ? field.placeholder : null
                "
                />
                <div
                  class="invalid-feedback"
                  *ngIf="!fieldText.valid && submitted"
                >
                  Please enter a value above.
                </div>
              </div>
              <div *ngSwitchCase="'number'" class="has-validation">
                <input
                  type="number"
                  name="{{ field.id }}"
                  id="{{ field.id }}"
                  [(ngModel)]="formData[field.id]"
                  [required]="field.required"
                  class="form-control"
                  #fieldText="ngModel"
                  [ngClass]="{ 'is-invalid': !fieldText.valid && submitted }"
                  [attr.placeholder]="
                  field.placeholder ? field.placeholder : null
                "
                />
                <div
                  class="invalid-feedback"
                  *ngIf="!fieldText.valid && submitted"
                >
                  Please enter a value above.
                </div>
              </div>
              <div *ngSwitchCase="'textarea'" class="has-validation">
                <textarea
                  name="{{ field.id }}"
                  id="{{ field.id }}"
                  class="form-control"
                  [(ngModel)]="formData[field.id]"
                  [required]="field.required"
                  #fieldText="ngModel"
                  [ngClass]="{ 'is-invalid': !fieldText.valid && submitted }"
                  [attr.placeholder]="
                  field.placeholder ? field.placeholder : null
                "
                ></textarea>
                <div
                  class="invalid-feedback"
                  *ngIf="!fieldText.valid && submitted"
                >
                  Please enter a value above.
                </div>
              </div>
              <div *ngSwitchCase="'checkbox'" class="has-validation">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="{{ field.id }}"
                  id="{{ field.id }}"
                  [(ngModel)]="formData[field.id]"
                  [required]="field.required"
                  #fieldText="ngModel"
                  [ngClass]="{ 'is-invalid': !fieldText.valid && submitted }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="!fieldText.valid && submitted"
                >
                  Please enter a value above.
                </div>
              </div>
              <div *ngSwitchCase="'select'" class="has-validation">
                <select
                  name="{{ field.id }}"
                  id="{{ field.id }}"
                  class="form-select"
                  [(ngModel)]="formData[field.id]"
                  [required]="field.required"
                  #fieldText="ngModel"
                  [ngClass]="{ 'is-invalid': !fieldText.valid && submitted }"
                >
                  <option
                    *ngFor="let option of field.config?.options"
                    value="{{ option }}"
                  >
                    {{ option }}
                  </option>
                </select>

                <div
                  class="invalid-feedback"
                  *ngIf="!fieldText.valid && submitted"
                >
                  Please enter a value above.
                </div>
              </div>
            </ng-container>
            <div class="form-text" *ngIf="field.instructions">
              {{ field.instructions }}
            </div>
          </div>
        </div>
        <div class="form-group mt-2 mb-3">
          <button class="btn btn-ys" [disabled]="formProcessing">Submit</button>
        </div>
      </div>
      <div class="form-text small" style="font-size: 11px; color: #999">
        By submitting this form you agree to our
        <a href="https://app.yoscenario.com/privacy" class="text-secondary" target="_blank"
          >privacy policy</a
        >
      </div>
    </form>
  </div>
</div>
<div class="user-tracking-form-bg"></div>
