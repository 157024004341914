import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditorService {
  public disableKeys = new BehaviorSubject(false);
  public deselectComponent = new BehaviorSubject(false);
  public selectedComponent = new BehaviorSubject(null);
  public activeComponent = new BehaviorSubject(null);
  public activeStep = new BehaviorSubject(null);
  public widerNav = new BehaviorSubject(false);
  public hotspotEditorActive = new BehaviorSubject(false);

  public showPreviewFromStep = new BehaviorSubject(null);
  public addChoice = new BehaviorSubject(null);
  public deleteStep = new BehaviorSubject(null);
  public isResizing = new BehaviorSubject(false);
  public copiedSettings = new BehaviorSubject(null);
  public instructions = new  BehaviorSubject(null);
  public panoramicImageActiveScene = new  BehaviorSubject(null);
  public hotspotEditorRefresh = new  BehaviorSubject(false);
  public redrawStepsView = new  BehaviorSubject(false);

  constructor() {}

  doAutoHeight(selectedComponent: any) {
    if (selectedComponent.autoHeight == true) {
      selectedComponent.height = 0;
      let componentElem = document.getElementById(
        'component-' + selectedComponent.id
      );

      // check for padding notation...
      let paddingOffset = 0;
      if (String(selectedComponent.style.padding).indexOf(' ') >= 0) {
        let paddingSplit = selectedComponent.style.padding.split(' ');
        if (paddingSplit[0] && paddingSplit[2]) {
          paddingOffset = +paddingSplit[0] + +paddingSplit[2];
        }
      } else {
        paddingOffset = selectedComponent.style.padding * 2;
      }

      if (componentElem) {
        let children = componentElem.children;
        let qlEditorElem: any = children[0];
        if (!qlEditorElem.className.toString().includes('ql-editor')) {
          // try this as it must be in active use?
          qlEditorElem = children[0].children[1].children[0];
        }

        qlEditorElem.style.height = 0;
        selectedComponent.height = qlEditorElem.scrollHeight + paddingOffset;
        qlEditorElem.style.height = '100%';
      }
    }
  }

  resizeControlsContainer(canvasSize: any, selectedComponent: any) {
    let controlsSelected = 0;

    let startingWidth = selectedComponent.width * 1 + 0;

    if (selectedComponent.data.showInventory) {
      controlsSelected++;
    }
    if (selectedComponent.data.showRestart) {
      controlsSelected++;
    }
    if (selectedComponent.data.showFullscreen) {
      controlsSelected++;
    }
    if (selectedComponent.data.showVolume) {
      controlsSelected++;
    }
    if (selectedComponent.data.showHelp) {
      controlsSelected++;
    }
    if (selectedComponent.data.showClose) {
      controlsSelected++;
    }
    if (selectedComponent.data.showBack) {
      controlsSelected++;
    }

    if (controlsSelected == 0) {
      controlsSelected = 1;
    }

    selectedComponent.width = controlsSelected * 42;

    // where are we?
    if (canvasSize.w == selectedComponent.pos.x + startingWidth) {
      selectedComponent.pos.x =
        selectedComponent.pos.x + (startingWidth - selectedComponent.width);
    }
  }

  setComponentName(component: any, content: any) {
    if (component && !component.name) {
      let typeCount = 0;
      let thisTypecount = 0;
      content.components.forEach((_component: any) => {
        if (component.type == _component.type) {
          typeCount++;
        }
        if (component.id == _component.id) {
          thisTypecount = typeCount * 1;
        }
      });

      if (thisTypecount == 0) {
        thisTypecount = typeCount;
      }
      if (thisTypecount == 1) {
        component.name =
          component.type.charAt(0).toUpperCase() + component.type.slice(1);
      } else {
        component.name =
          component.type.charAt(0).toUpperCase() +
          component.type.slice(1) +
          ' ' +
          thisTypecount;
      }
    }
  }

  getPixelsByAngle(
    x: number,
    y: number,
    width: number,
    height: number,
    angle: number
  ) {
    var radians = (angle * Math.PI) / 180;
    return [
      //upper left
      [
        x +
          width / 2 +
          (width / -2) * Math.cos(radians) -
          (height / -2) * Math.sin(radians),
        y +
          height / 2 +
          (width / -2) * Math.sin(radians) +
          (height / -2) * Math.cos(radians),
      ],
      //upper right
      [
        x +
          width / 2 +
          (width / 2) * Math.cos(radians) -
          (height / -2) * Math.sin(radians),
        y +
          height / 2 +
          (width / 2) * Math.sin(radians) +
          (height / -2) * Math.cos(radians),
      ],
      //bottom right
      [
        x +
          width / 2 +
          (width / 2) * Math.cos(radians) -
          (height / 2) * Math.sin(radians),
        y +
          height / 2 +
          (width / 2) * Math.sin(radians) +
          (height / 2) * Math.cos(radians),
      ],
      //bottom left
      [
        x +
          width / 2 +
          (width / -2) * Math.cos(radians) -
          (height / 2) * Math.sin(radians),
        y +
          height / 2 +
          (width / -2) * Math.sin(radians) +
          (height / 2) * Math.cos(radians),
      ],
    ];
  }

  getLowestPoint(
    x: number,
    y: number,
    width: number,
    height: number,
    angle: number
  ) {
    let newBottom = -20;
      let rads = this.getPixelsByAngle(x, y, width, height, angle);

      let highestY = 0;
      let lowestY = 99999;
      rads.forEach((rad: any) => {
        if (rad[1] > highestY) {
          highestY = rad[1];
        }
        if (rad[1] < lowestY) {
          lowestY = rad[1];
        }
      });

      if (highestY > 0) {
        newBottom = (lowestY - highestY + height) / 2 - 20;
      }
    
    return newBottom;
  }
  
}
